import {
  DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY,
  IFRAME_COMMS_MESSAGE_TYPE
} from './const';

import { Switchboard } from '@superset-ui/switchboard';
import { getGuestTokenRefreshTiming } from './guestTokenRefresh';

export type GuestTokenFetchFn = () => Promise<string>;

export type UiConfigType = {
  hideTitle?: boolean
  hideTab?: boolean
  hideChartControls?: boolean
  filters?: {
    [key: string]: boolean | string | undefined
    visible?: boolean
    expanded?: boolean
    native?: string
  }
}

export type EmbedDashboardParams = {
  id: string
  supersetDomain: string
  mountPoint: HTMLElement
  fetchGuestToken: GuestTokenFetchFn
  dashboardUiConfig?: UiConfigType
  debug?: boolean
}

export type Size = {
  width: number, height: number
}

export type EmbeddedDashboard = {
  getScrollSize: () => Promise<Size>
  unmount: () => void
  getDashboardPermalink: (anchor: string) => Promise<string>
  getActiveTabs: () => Promise<string[]>
}

export async function embedDashboard({
  id,
  supersetDomain,
  mountPoint,
  fetchGuestToken,
  dashboardUiConfig,
  debug = false
}: EmbedDashboardParams): Promise<EmbeddedDashboard> {
  function log(...info: unknown[]) {
    if (debug) {
      console.debug(`[superset-embedded-sdk][dashboard ${id}]`, ...info);
    }
  }

  log('embedding');

  if (supersetDomain.endsWith("/")) {
    supersetDomain = supersetDomain.slice(0, -1);
  }

  function calculateConfig() {
    let configNumber = 0
    if (dashboardUiConfig) {
      if (dashboardUiConfig.hideTitle) {
        configNumber += 1
      }
      if (dashboardUiConfig.hideTab) {
        configNumber += 2
      }
      if (dashboardUiConfig.hideChartControls) {
        configNumber += 8
      }
    }
    return configNumber
  }

  async function mountIframe(): Promise<Switchboard> {
    return new Promise(resolve => {
      const iframe = document.createElement('iframe');
      iframe.style.border = 'none';
      const dashboardConfig = dashboardUiConfig ? `?uiConfig=${calculateConfig()}` : ""
      const filterConfig = dashboardUiConfig?.filters || {}
      const filterConfigKeys = Object.keys(filterConfig)
      const filterConfigUrlParams = filterConfigKeys.length > 0
        ? "&"
        + filterConfigKeys
          .map(key => DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY[key] + '=' + filterConfig[key]).join('&')
        : ""

      iframe.sandbox.add("allow-same-origin");
      iframe.sandbox.add("allow-scripts");
      iframe.sandbox.add("allow-presentation");
      iframe.sandbox.add("allow-downloads");
      iframe.sandbox.add("allow-forms");
      iframe.sandbox.add("allow-popups");

      iframe.addEventListener('load', () => {

        const commsChannel = new MessageChannel();
        const ourPort = commsChannel.port1;
        const theirPort = commsChannel.port2;

        iframe.contentWindow!.postMessage(
          { type: IFRAME_COMMS_MESSAGE_TYPE, handshake: "port transfer" },
          supersetDomain,
          [theirPort],
        )
        log('sent message channel to the iframe');

        resolve(new Switchboard({ port: ourPort, name: 'superset-embedded-sdk', debug }));
      });

      iframe.src = `${supersetDomain}/embedded/${id}${dashboardConfig}${filterConfigUrlParams}`;
      //@ts-ignore
      mountPoint.replaceChildren(iframe);
      log('placed the iframe')
    });
  }

  const [guestToken, ourPort]: [string, Switchboard] = await Promise.all([
    fetchGuestToken(),
    mountIframe(),
  ]);

  ourPort.emit('guestToken', { guestToken });
  log('sent guest token');

  async function refreshGuestToken() {
    const newGuestToken = await fetchGuestToken();
    ourPort.emit('guestToken', { guestToken: newGuestToken });
    setTimeout(refreshGuestToken, getGuestTokenRefreshTiming(newGuestToken));
  }

  setTimeout(refreshGuestToken, getGuestTokenRefreshTiming(guestToken));

  function unmount() {
    log('unmounting');
    //@ts-ignore
    mountPoint.replaceChildren();
  }

  const getScrollSize = () => ourPort.get<Size>('getScrollSize');
  const getDashboardPermalink = (anchor: string) =>
    ourPort.get<string>('getDashboardPermalink', { anchor });
  const getActiveTabs = () => ourPort.get<string[]>('getActiveTabs')

  return {
    getScrollSize,
    unmount,
    getDashboardPermalink,
    getActiveTabs,
  };
}
