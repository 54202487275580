import React, { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { useTheme } from '@mui/material';

interface CalculatorLayoutProps {
    children?: ReactNode;
}

const CalculatorLayout: FC<CalculatorLayoutProps> = ({ children }) => {
    const theme = useTheme();

    return (
        <main
            id='calculator_layout_container'
            style={{
                width: '100vw',
                minHeight: "100vh",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                background: theme.palette.funding_calculator.landing_page.background,
            }}
        >
            {children ?? <Outlet />}
        </main>
    );
};

export default CalculatorLayout;
