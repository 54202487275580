import { useDispatch } from 'react-redux';
import { UpdateFundingCalculationPayload } from '../../api/fundingCalculator';
import { useUpdateFundingCalculataionMutation, useLazyGetfundingCalculatorByIdQuery } from '../../api/fundingCalculator';
import { setFundingCalculator } from '../../stores/FundingCalculator/slices';
import { convertToFundingCalculations } from '../../utils/common';

const useUpdateFundingCalculation = () => {
  const [updateFundingCalculataion] = useUpdateFundingCalculataionMutation();
  const [getfundingCalculatorById] = useLazyGetfundingCalculatorByIdQuery();

  const dispatch = useDispatch();

  const updateCalculation = async (payload: UpdateFundingCalculationPayload) => {
    try {
      await updateFundingCalculataion(payload);

      const response = await getfundingCalculatorById(payload.id).unwrap();

      if (response.data) {
        const calculation = convertToFundingCalculations(response.data);
        dispatch(setFundingCalculator(calculation));

        return calculation;
      }
    } catch (err) {
      console.error('Error while editing funding calculation :', err);
    }
  };

  return { updateCalculation };
};

export default useUpdateFundingCalculation;
