import { FC, useState, useCallback, useEffect } from 'react';
import { Box, useTheme, useMediaQuery, TextField, Slider, InputAdornment, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as LightningIcon } from '../../../assets/icons/lightning.svg';
import { DEFAULT_PAYBACK_PERIOD_OPTIONS, PAYBACK_PERIOD_OPTIONS, ROAS_PAYBACK_PERIOD_VALUE } from '../../../constants/constants';
import { CalculatorChartData, CalculatorChartDataItem } from '../../../stores/FundingCalculator/types/fundingCalculator';
import { ReactComponent as InfoIconDark } from '../../../assets/icons/info_icon_dark.svg';
import { labels, messages } from '@utils/translations/en';

type CalculatorChartPropType = {
    paybackPeriod: number;
    setPaybackPeriod?: React.Dispatch<React.SetStateAction<number>>;
    monthlyPaybackValues: CalculatorChartData;
    handleChangeMonthlyPaybackValues: (data: CalculatorChartData) => void;
};

const CalculatorChartInput: FC<CalculatorChartPropType> = (props) => {
    const {
        paybackPeriod,
        setPaybackPeriod = (val: number) => null,
        monthlyPaybackValues,
        handleChangeMonthlyPaybackValues,
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [userInputs, setUserInputs] = useState<CalculatorChartData>([]);

    // Hook to update both monthlyPaybackValues and userInputs based on paybackPeriod defaults
    useEffect(() => {
        if (paybackPeriod) {
            const defaultValues = DEFAULT_PAYBACK_PERIOD_OPTIONS[paybackPeriod];
            const data = defaultValues.map((item: CalculatorChartDataItem) => {
                return {
                    month: item.month,
                    value: item.value,
                };
            });

            setUserInputs(data);
            handleChangeMonthlyPaybackValues(data);
        }

    }, [paybackPeriod]);

    // Hook to update monthlyPaybackValues based on userInputs
    useEffect(() => {
        if (userInputs.length > 0) {
            const inputValues = userInputs.filter(input => input.month <= paybackPeriod);
            handleChangeMonthlyPaybackValues(inputValues);
        }
    }, [userInputs]);

    // Hook to load userInputs based on monthlyPaybackValues
    useEffect(() => {
        if (userInputs.length === 0) {
            setUserInputs(monthlyPaybackValues);
        }
    }, [monthlyPaybackValues]);

    // Function to record and userInputs
    const handleInputChange = useCallback(
        (month: number, newValue: string) => {
            let value = parseInt(newValue.replace(/[^0-9]/g, ''));
            if (isNaN(value)) {
                value = 0;
            }
            if (value >= 0 && value <= 999) {
                setUserInputs((prevInputs) => {
                    const existingInputIndex = prevInputs.findIndex(input => input.month === month);
                    const updatedInputs = [...prevInputs];
                    if (existingInputIndex >= 0) {
                        updatedInputs[existingInputIndex] = { month, value };
                    } else {
                        updatedInputs.push({ month, value });
                    }
                    return updatedInputs;
                });
            }
        },
        [],
    );

    const getMonthValue = (month: number) => {
        const monthValue = userInputs.find(input => input.month === month)?.value;

        return monthValue !== undefined ? `${monthValue.toFixed(0)}` : '';
    };
    const getAdornmentPosition = (month: number) => {
        const monthValue = userInputs.find(input => input.month === month)?.value.toString();
        if(monthValue) {
            if (monthValue.length > 2)
                return '50px'
            else if (monthValue.length > 1) return '60px'
            else return '70px'
        }
        
    }

    return (
        <Box id="calculator_landing_body_chart_inputs">
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    mb: '1rem',
                    gap: "1rem"
                }}
            >
                {ROAS_PAYBACK_PERIOD_VALUE.filter((option) => option.value <= paybackPeriod)
                    .map((option) => (
                        <Box
                            id={`Month-${option.value}-currency-input-field`}
                            key={option.value}
                            sx={{
                                width: '7rem',
                                height: "max-content",
                                display: "flex",
                                flexDirection: "column",
                                py: "0.5rem",
                                px: "0.25rem",
                                border: "1px solid #16293699",
                                borderRadius: "1rem",
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '0.6rem',
                                    color: "#162936",
                                    pl: "0.5rem",
                                    pb: "0.5rem"
                                }}
                                fontFamily="Manrope, sans-serif"
                            >
                                {`MONTH ${option.value}`}
                            </Typography>
                            <TextField
                                id={`Month-${option.value}-textfield`}
                                sx={{
                                    width: '100%',
                                    border: 'none',
                                    input: {
                                        fontSize: '1rem',
                                        paddingLeft: '0.5rem',
                                        paddingRight: '0',
                                        paddingTop: '0',
                                        paddingBottom: '0',
                                        textAlign: 'left',
                                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                            WebkitAppearance: 'none',
                                            margin: 0,
                                        },
                                        '&[type=number]': {
                                            MozAppearance: 'textfield',
                                        }
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'transparent',
                                        '& fieldset': {
                                            border: 'none',
                                            p: 0
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                type="text"
                                onChange={(e) => handleInputChange(option.value, e.target.value)}
                                value={getMonthValue(option.value)}
                                InputProps={{
                                    endAdornment: getMonthValue(option.value) ? (
                                      <InputAdornment
                                        disableTypography
                                        position="end"
                                        sx={{
                                          right: getAdornmentPosition(option.value),
                                          position: "absolute",
                                          color: '#162936',
                                          fontFamily: 'Manrope, sans-serif',
                                          fontWeight: 550
                                        }}
                                      >
                                        %
                                      </InputAdornment>
                                    ) : null,
                                  }}
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    );
}

export default CalculatorChartInput;
