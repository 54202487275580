import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Loader from '../Loader';

type FullPageLoaderWithTextProps = {
    text?: string;
};

const FullPageLoaderWithText: React.FC<FullPageLoaderWithTextProps> = ({ text }) => {
    const theme = useTheme();
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 9988,
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: theme.palette.common.black,
                    opacity: 0.75,
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 9999,
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box
                    sx={{
                        height: '20rem',
                        width: '20rem',
                        borderRadius: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        background: theme.palette.common.white,
                    }}
                >
                    <Typography sx={{ textAlign: 'center', pt: 4, fontWeight: 500 }}>
                        {text || ''}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Loader customWidth={'50%'} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FullPageLoaderWithText;
