import { createSlice } from '@reduxjs/toolkit';
import { FundingCalculatorState } from "./types/fundingCalculator";

const initialState: FundingCalculatorState = {
    fundingCalculations: {
        id: "",
        email: "",
        requestId: "",
        variables: {
            pvxFinancing: 0,
            paybackPeriodInMonths: 0,
            latestMonthFinancials: {
                netRevenue: 0,
                marketingSpends: 0,
                latestMonthCashBalance: 0,
                operatingExpenses: 0,
                profitBeforeTaxes: 0,
            },
            revenueChurnPerMonth: 0,
            uaIncreasePerMonth: 0,
        },
        summary: {
            cashCost: 0,
            eligibleFund: 0,
            annualFacilitySize: 0,
            totalInvestmentPaybackAmount: 0,
            totalInvestmentInterestPaid: 0,
        },
        charts: {
            cumulative_roas: {
                datasets: [],
                title: "Cumulative ROAS",
                description: "Net Cumulative Return on Advertising Spend over time."
            },
            revenue: {
                datasets: [],
                title: "Revenue Growth",
                description: "Comparison of revenue growth with PvX Funding and Current UA Spends."
            },
            cumulative_revenue: {
                datasets: [],
                title: "Cumulative Revenue Growth",
                description: "Cumulative comparison of revenue growth with PvX Funding and Current UA Spends."
            },
            monthly_marketing_spends: {
                datasets: [],
                title: "Monthly Marketing Spends",
                description: "Allocation of marketing spends between PvX Funding and Company Funding."
            },
            profit_before_taxes: {
                datasets: [],
                title: "Profit Before Taxes",
                description: "Profit comparison before taxes with PvX Funding and Current UA Spends."
            },
            cumulative_profit_before_taxes: {
                datasets: [],
                title: "Cumulative Profit Before Taxes",
                description: "Cumulative profit comparison before taxes with PvX Funding and Current UA Spends."
            },
            ending_cash_balance: {
                datasets: [],
                title: "Ending Cash Balance",
                description: "Comparison of ending cash balance with PvX Funding and Status Quo."
            },
            interest_paid: {
                datasets: [],
                title: 'Interest Paid',
                description: 'Comparison of interest paid'
            }
        }
    }
};

export const FundingCalculatorData = createSlice({
    name: 'FundingCalculator',
    initialState,
    reducers: {
        setFundingCalculator: (state, action) => {
            state.fundingCalculations = action.payload;
        },
        resetFundingCalculator: () => initialState
    }
});

export const {
    setFundingCalculator,
    resetFundingCalculator,
} = FundingCalculatorData.actions;

export default FundingCalculatorData.reducer;
