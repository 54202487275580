import { useAddFilterValuesMutation, useLazyGetSubModuleFiltersQuery } from '../../api/userModules';
import { useDispatch } from 'react-redux';
import { setSubModuleFilterOptions } from '../../stores/Modules/slice';

const useSubmoduleFilterValues = () => {
    const dispatch = useDispatch();
    const [addFilterValues, { isLoading, isError }] = useAddFilterValuesMutation();
    const [getSubModuleFilters] = useLazyGetSubModuleFiltersQuery();

    const addFilterValue = async (payload: { moduleId: string, submoduleId: string, filterId: string, values: string[] }) => {
        try {
            const response = await addFilterValues(payload).unwrap();
            if (response?.code) {
                const res = await getSubModuleFilters(payload.submoduleId).unwrap();
                if (res?.data?.submoduleFilterValues?.length) {
                    const { submoduleFilterValues } = res.data;
                    dispatch(setSubModuleFilterOptions({ module_id: payload.moduleId, sub_module_id: payload.submoduleId, filter_options: submoduleFilterValues }));
                }
            }
            return response;
        } catch (err) {
            console.error(err);
        }
    };

    return { addFilterValue, isLoading, isError };
};

export default useSubmoduleFilterValues;
