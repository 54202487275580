const useAuth = () => {
  const loginUrl = `${process.env.REACT_APP_KEYCLOAK_V2_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?response_type=code&client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KEYCLOAK_REDIRECT_URI}`;

  const keycloakLogoutUrl = `${process.env.REACT_APP_KEYCLOAK_V2_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`
  const redirectUri = `${process.env.REACT_APP_DOMAIN}/auth`;
  const logoutUrl = `${keycloakLogoutUrl}?post_logout_redirect_uri=${redirectUri}&client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`

  return { loginUrl, logoutUrl };
};

export default useAuth;
