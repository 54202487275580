import { useDeleteUserMutation } from '../../api/userList';
import useUserList from './useUserList';

const useDeleteUser = () => {
  const [deleteUser] = useDeleteUserMutation();
  const { getUserList } = useUserList();

  const deleteUserById = async (payload: {id: string}) => {
    try {
      const response = await deleteUser(payload).unwrap();

      if (response.code) {
        getUserList({});
      }
      return response;
    } catch (err) {
      console.error('Error while deleting user :', err);
    }
  };

  return { deleteUserById };
};

export default useDeleteUser;
