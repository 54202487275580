import { useEffect, useState } from "react";
import {
  AccordionDetails,
  Checkbox,
  Box,
  Typography,
  Paper,
  InputBase,
  useTheme,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { SubmoduleFilter } from "../../../stores/Modules/types/modules";

type SelectedPropType = {
  type?: string;
  options: {
    name: string;
    id: string;
    position?: number;
  }[];
};

export type FilterType = {
  name: string;
  options: SubmoduleFilter[];
};

const FilterRow = (props: {
  filter: FilterType,
  setSelectedOptionsArray: React.Dispatch<React.SetStateAction<SelectedPropType[]>>,
  selectedFilters: string[];
}) => {
  const theme = useTheme();
  const { filter, setSelectedOptionsArray, selectedFilters } = props;
  const [search, setSearch] = useState<string>("");
  const [selected, setSelected] = useState<Array<SelectedPropType>>([
    {
      type: filter.name || '',
      options: filter.options.filter((option) => option.isSelected || selectedFilters.includes(option.id)).map((option) => ({
        name: option.filterValue,
        id: option.id
      }))
    }
  ]);

  const getSelected = (type: string): SelectedPropType =>
    selected?.find((e) => e.type === type) || { options: [] };

  const getUnselected = (filter: FilterType) => {
    const selectedVal = getSelected(filter.name);
    const selectedIds = selectedVal?.options?.map((e) => e.id);
    return filter.options
      ?.filter((e) => !selectedIds?.includes(e.id))
      .filter((e) => e.filterValue.indexOf(search) > -1);
  };

  const markSelected = (
    type: string,
    index: number,
    item: SubmoduleFilter
  ) => {
    const indexVal = selected.findIndex((i) => i.type === type);
    const result = [...selected] || [];
    const options = result[indexVal]?.options || [];
    options.push({
      name: item.filterValue,
      id: item.id,
      position: index,
    });
    if (indexVal > -1) result.splice(indexVal, 1, { type, options });
    else result.push({ type, options });
    setSelected(result);
  };

  const markUnSelected = (index: number, type: string) => {
    const indexVal = selected.findIndex((item) => item.type === type);
    const result = [...selected] || [];
    const options = result[indexVal]?.options || [];
    options.splice(index, 1);
    if (indexVal > -1) result.splice(indexVal, 1, { type, options });
    else result.push({ type, options });
    setSelected(result);
  };

  const handleSelectAll = (isSelectAll: boolean) => {
    if (isSelectAll) {
      const updatedOptions = filter.options.map((i) => ({
        name: i.filterValue,
        id: i.id
      }));

      setSelected([{ type: filter.name, options: updatedOptions }]);
    } else {
      setSelected([{ type: filter.name, options: [] }]);
    }
  };

  useEffect(() => {
    setSelectedOptionsArray((prev) => {
      // Iterate over the new selections
      selected.forEach((newItem) => {
        // Check if the type already exists in the previous state
        const existingItemIndex = prev.findIndex((item) => item.type === newItem.type);

        if (existingItemIndex !== -1) {
          // Type exists, so update the options
          prev[existingItemIndex] = {
            ...prev[existingItemIndex],
            options: newItem.options,
          };
        } else {
          // Type doesn't exist, so add the new item
          prev.push(newItem);
        }
      });

      // Return the updated array
      return [...prev];
    });
  }, [selected]);

  return (
    <AccordionDetails sx={{ display: "flex", flexDirection: "column", overflow: 'hidden' }}>
      <Paper
        component="form"
        sx={{
          height: 45,
          px: "19px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "28px",
          mb: 3,
          backgroundColor: "#2F736E14",
          boxShadow: 'none'
        }}
      >
        <InputBase
          placeholder={`Search ${filter.name}`}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        <SearchIcon />
      </Paper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: getSelected(filter.name)?.options?.length > 0 ? 'space-between' : 'flex-end',
          width: '100%'
        }}
      >
        {getSelected(filter.name)?.options?.length > 0 && (<Typography fontSize={"14px"} fontWeight={700} sx={{ color: "#989CA2" }}>
          Selected
        </Typography>)}
        {!search && (<Typography
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            color: "#51B8B0",
            cursor: "pointer",
          }}
          onClick={() => handleSelectAll(!Boolean(getSelected(filter.name)?.options?.length === filter.options.length))}
        >
          {Boolean(getSelected(filter.name)?.options?.length === filter.options.length) ? 'Remove All' : 'Select All'}
        </Typography>)}
      </Box>
      {
        getSelected(filter.name)?.options?.length > 0 && (
          <Box
            sx={{
              maxHeight: getUnselected(filter)?.length === 0 ? "600px" : "275px",
              overflowY: "scroll"
            }}
          >
            {getSelected(filter.name)?.options?.map((option, i) => (
              search ? (
                option.name.includes(search) ? (
                  <Box
                    key={option.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography color={"#162936"} fontSize={'16px'} fontWeight={500}>{option.name}</Typography>
                    <Checkbox
                      checked
                      onClick={() => markUnSelected(i, filter.name)}
                      sx={{
                        color: "#2F736E",
                        '&.Mui-checked': {
                          color: "#2F736E",
                        }
                      }}
                    />
                  </Box>
                ) : (<></>)
              ) : (
                <Box
                  key={option.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography color={"#162936"} fontSize={'16px'} fontWeight={500}>{option.name}</Typography>
                  <Checkbox
                    checked
                    onClick={() => markUnSelected(i, filter.name)}
                    sx={{
                      color: "#2F736E",
                      '&.Mui-checked': {
                        color: "#2F736E",
                      }
                    }}
                  />
                </Box>
              )
            ))}
          </Box>
        )
      }
      {
        getSelected(filter.name)?.options?.length > 0 && getUnselected(filter).length > 0 && (
          <>
            <Typography fontSize={"14px"} fontWeight={700} sx={{ color: "#989CA2", mt: 2, mb: 1 }}>
              Un-Selected
            </Typography>
          </>
        )
      }
      <Box
        sx={{
          maxHeight: getSelected(filter.name)?.options?.length === 0 ? "600px" : "275px",
          overflowY: "scroll"
        }}
      >
        {getUnselected(filter)?.map((option, i) => (
          <Box
            key={option.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography color={"#162936"} fontSize={'16px'} fontWeight={500}>{option.filterValue}</Typography>
            <Checkbox
              onClick={() => markSelected(filter.name, i, option)}
              sx={{
                color: "#2F736E",
                '&.Mui-checked': {
                  color: "#2F736E",
                }
              }}
            />
          </Box>
        ))}
      </Box>
    </AccordionDetails >
  );
};

export default FilterRow;
