import { useLazyGetfundingCalculatorQuery } from '../../api/fundingCalculator';

const useCalculationIdByEmail = () => {
  const [getfundingCalculator, { isLoading, isError }] = useLazyGetfundingCalculatorQuery();


  const getCalculationIdByEmail = async (id: string, isEmail?: boolean) => {
    try {
      const response = await getfundingCalculator({id, isEmail}).unwrap();
      return response;
    } catch (err) {
      console.error('Error while fetching funding calculator id by registered email-id :', err);
    }
  };

  return { getCalculationIdByEmail, isLoading, isError };
};

export default useCalculationIdByEmail;
