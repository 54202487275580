import { useLazyGetUserQuery } from '../../api/userDetails';
import { useDispatch } from 'react-redux';
import { setUser } from '../../stores/User/slice';

const useUserDetails = () => {
  const [getUser, { isLoading, isError }] = useLazyGetUserQuery();

  const dispatch = useDispatch();

  const getUserDetails = async () => {
    try {
      const response = await getUser({}).unwrap();

      if (response.data) {
        dispatch(setUser(response.data));

        return response.data;
      }
    } catch (err) {
      console.error('Error while fetching user :', err);
    }
  };

  return { getUserDetails, isLoading, isError };
};

export default useUserDetails;
