import { configureStore, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import baseApi, { noAuthBaseApi } from '../services/api';
import AppData, { setApiErrorLog } from './App/slice';
import UserData from './User/slice';
import UserListData from './UserList/slice';
import ModulesData from './Modules/slice';
import SelectedUserData from './SelectedUser/slice';
import CompanyListData from './CompanyList/slice';
import FundingCalculatorData from './FundingCalculator/slices';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action: any) => {
  if (typeof action?.payload?.status === 'number') {
    const errorData = {
      endpoint:
        action?.meta?.baseQueryMeta?.request?.url?.replace(
          `${process.env.REACT_APP_API_DOMAIN}/`,
          ''
        ) || '',
      method: action?.meta?.baseQueryMeta?.request?.method || null,
      payload:
        { ...action?.meta?.arg?.originalArgs, password: undefined } || null,
      response: action?.payload?.data || null,
      errorStatus: action?.payload?.status || null
    };

    store.dispatch(setApiErrorLog(errorData));

    // if (action?.payload?.status === 404) {
    //   window?.location?.replace(NAV_ROUTES.PAGE_NOT_FOUND);
    // }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [noAuthBaseApi.reducerPath]: noAuthBaseApi.reducer,
    AppData,
    UserData,
    ModulesData,
    UserListData,
    SelectedUserData,
    CompanyListData,
    FundingCalculatorData,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
      noAuthBaseApi.middleware,
      rtkQueryErrorLogger
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
