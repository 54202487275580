import { useLazyGetModulesQuery, useLazyGetSubModuleFiltersQuery, useLazyGetSubModulesQuery } from '../../api/userModules';
import { useDispatch } from 'react-redux';
import { setAdminFetchCount, setAuthorisedModule, setAuthorisedSubModule, setLoadCount, setSubModuleFilterOptions } from '../../stores/Modules/slice';

const useUserModules = () => {
  const dispatch = useDispatch();
  const [getModules, { isLoading: isModuleLoading, isError: isModuleError }] = useLazyGetModulesQuery();
  const [getSubModules, { isLoading: isSubModuleLoading, isError: isSubModuleError }] = useLazyGetSubModulesQuery();
  const [getSubModuleFilters, { isLoading: isFilterLoading, isError: isFilterError }] = useLazyGetSubModuleFiltersQuery();

  const isLoading = isModuleLoading || isSubModuleLoading || isFilterLoading;
  const isError = isModuleError || isSubModuleError || isFilterError;

  const getUserModules = async (isAdmin: boolean) => {
    try {
      // Fetch user modules
      const modulesResponse = await getModules({}).unwrap();

      if (modulesResponse?.data?.modules?.length) {
        const { modules } = modulesResponse.data;
        dispatch(setAuthorisedModule(modules));

        // For each user module, fetch submodules in parallel
        const submodulePromises = modules.map(async (module: any) => {
          try {
            const submodulesResponse = await getSubModules(module.id).unwrap();
            if (submodulesResponse?.data?.subModules?.length) {
              const { subModules } = submodulesResponse.data;
              dispatch(setAuthorisedSubModule({ module_id: module.id, sub_modules: subModules }));

              if (isAdmin) {
                // For each user submodule, fetch filters in parallel
                const filterPromises = subModules.map(async (subModule: any) => {
                  try {
                    const filtersResponse = await getSubModuleFilters(subModule.id as string).unwrap();
                    if (filtersResponse?.data?.submoduleFilterValues?.length) {
                      dispatch(setSubModuleFilterOptions({
                        module_id: module.id,
                        sub_module_id: subModule.id,
                        filter_options: filtersResponse?.data?.submoduleFilterValues
                      }));
                    }
                  } catch (err) {
                    console.error(`Error fetching filters for submodule ${subModule.id}:`, err);
                  }
                });
  
                await Promise.all(filterPromises);

                // Update admin loaded count
                dispatch(setAdminFetchCount());
              }
            }
          } catch (err) {
            console.error(`Error fetching submodules for module ${module.id}:`, err);
          }
        });

        await Promise.all(submodulePromises);
      }
    } catch (err) {
      console.error('Error fetching modules:', err);
    } finally {
      // Update module loaded count
      dispatch(setLoadCount());
    }
  };

  return { getUserModules, isLoading, isError };
};

export default useUserModules;
