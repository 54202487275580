import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Popover, RadioGroup, SelectWithLabel } from "../../../components";
import { Company } from "../../../stores/CompanyList/types/companyList";
import { UserPayload } from "../../../api/company";
import { titles } from "@utils/translations/en";
import useDeleteUser from "@hooks/api/useDeleteUser";
import { NAV_ROUTES } from "@constants/navRoutes";
import { setToastMessage } from "@stores/App/slice";

type UserDetailsProp = {
  user?: UserPayload | null;
  companies?: Company[];
  isUserDetails?: boolean;
  toggleModuleSection?: (val: Boolean) => void;
  onUpdateData: (field: string, value: any) => void;
};
const UserDetailsSection: FC<UserDetailsProp> = (props) => {
  const { user, isUserDetails = false, toggleModuleSection, companies, onUpdateData } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deleteUserById } = useDeleteUser();

  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  const getCompanyOptions = () => {
    return companies?.length ? companies.map((val) => ({ label: val.name, value: val.companyId })) : [];
  };

  const deleteUser = async (id?: string) => {
    if (id) {
      const res = await deleteUserById({ id });
      dispatch(setToastMessage(Boolean(res?.code) ? `${user?.email} deleted !` : `Failed to delete ${user?.email}, please try again !`));
      setShowDeletePopUp(false)
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    }
  };

  return (
    <>
      <Box sx={{ mt: 3.5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            sx={{
              color: "#162936",
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {titles.USER_DETAILS}
          </Typography>
          {isUserDetails && 
            <Button
              sx={{ color: "#FF6347", textTransform: "none" }}
              onClick={() => setShowDeletePopUp(true)}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem" }}>
                <DeleteOutlineIcon
                  htmlColor="#FF6347"
                  sx={{
                    height: '1rem',
                    width: '1rem',
                    cursor: "pointer",
                    backgroundColor: "#F7F7F7",
                    borderRadius: isMobile ? 3 : 20,
                  }}
                />
                <Typography
                  sx={{
                    color: "#FF6347",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Delete User
                </Typography>
              </Box>
            </Button>
          }
        </Box>
        <Box
          sx={{
            mt: 2.5,
            display: "grid",
            gap: 2,
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
            width: "100%",
            mb: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <SelectWithLabel
              label="Company"
              labelId="company-select-label"
              menuItems={getCompanyOptions()}
              onChange={(value) => onUpdateData('companyId', value)}
              isAddNew={true}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                  },
                },
              }}
              value={user?.companyId}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <TextField
                label="Email Id"
                id="email-select-label"
                value={user?.email}
                onChange={(e) => onUpdateData('email', e.target.value)}
                disabled={isUserDetails}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F736E",
                  },
                  "& .MuiInputBase-root": {
                    color: "#162936",
                    fontSize: "1rem",
                    fontWeight: 550,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2F736E",
                    },
                    "&:hover fieldset": {
                      borderColor: isUserDetails ? "" : "#2F736E",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#2F736E",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <TextField
                label="User Name"
                id="userName-select-label"
                value={user?.username}
                onChange={(e) => onUpdateData('username', e.target.value)}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#2F736E",
                  },
                  "& .MuiInputBase-root": {
                    color: "#162936",
                    fontSize: "1rem",
                    fontWeight: 550,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#2F736E",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2F736E",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#2F736E",
                    },
                  },
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          gap: 2,
          pt: isMobile ? 2 : 0,
          alignItems: "center",
          justifyContent: isMobile ? "center" : "flex-start",
          opacity: isUserDetails ? "0.5" : "1",
          pointerEvents: isUserDetails ? "none" : "cursor"
        }}>
          {/* {isUserDetails && (
          <Switch onValueChange={toggleModuleSection} defaultValue />
        )} */}
          <RadioGroup
            value={user?.userRole || "VIEWER"}
            options={[
              { value: "ADMIN", label: "Admin" },
              { value: "VIEWER", label: "Viewer" },
            ]}
            name="admin_viewer_radio"
            row
            handleChange={(value) => onUpdateData('userRole', value)}
          />
        </Box>
      </Box>
      {showDeletePopUp && (
        <Popover
          title="Delete user"
          subTitle="Are you sure you want to delete this user ?"
          primaryButton="Delete"
          onClose={() => setShowDeletePopUp(false)}
          onAction={() => deleteUser(user?.id)}
        />
      )}
    </>
  );
};

export default UserDetailsSection;
