import React, { useEffect, useState, useRef } from 'react';

interface OdometerProps {
    value: string;  // Percentage value or percentage range, e.g. '15%' or '10% - 20%'
    loading: boolean;
}

const Odometer: React.FC<OdometerProps> = ({ value, loading }) => {
    const [displayValue, setDisplayValue] = useState<string>(value);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    const hasMounted = useRef(false);

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            setDisplayValue(value);
            return;
        }

        if (!value || value === '0%') {
            setDisplayValue(value);
            return;
        }

        let timeout: NodeJS.Timeout | undefined;
        let elapsed = 0;
        const totalDuration = 1000;  // Total animation duration in milliseconds
        const frameRate = 60;  // Animation frame rate (60 FPS)
        const frameTime = 1000 / frameRate;  // Time per frame
        const totalFrames = Math.floor(totalDuration / frameTime);  // Number of frames for the animation

        setIsAnimating(true);

        const [startLow, startHigh] = parsePercentageRange(displayValue);
        const [endLow, endHigh] = parsePercentageRange(value);

        const animate = () => {
            elapsed++;

            const progress = elapsed / totalFrames;

            const newLow = startLow + (endLow - startLow) * progress;
            const newHigh = startHigh + (endHigh - startHigh) * progress;

            // Check if it's a range or a single value
            if (startLow === startHigh) {
                setDisplayValue(`${Math.floor(newLow)}%`);
            } else {
                setDisplayValue(`${Math.floor(newLow)}% - ${Math.floor(newHigh)}%`);
            }

            if (elapsed < totalFrames) {
                timeout = setTimeout(animate, frameTime);
            } else {
                setDisplayValue(value);  // Set the final value after animation completes
                setIsAnimating(false);
            }
        };

        animate();

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [value]);

    const parsePercentageRange = (percentage: string): [number, number] => {
        if (percentage.includes('-')) {
            const [low, high] = percentage.split('-').map((part) => parseFloat(part.trim().replace('%', '')));
            return [low, high];
        } else {
            const singleValue = parseFloat(percentage.replace('%', ''));
            return [singleValue, singleValue];  // Treat it as a single value, not a range
        }
    };

    return <span>{displayValue}</span>;
};

export default Odometer;
