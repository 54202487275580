import { FC } from 'react';
import { Box, useTheme, useMediaQuery, Button, Typography } from '@mui/material';
import footerBackgroundImage from "@assets/images/v2/footer-bg.png";
import footerBackgroundMobileImage from "@assets/images/v2/footer-bg-mob.png";

const CalculatorFooter: FC = () => {
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const is1250x = useMediaQuery('(max-width:1250px)');;
    const is1100x = useMediaQuery('(max-width:1100px)');;

    return (
        <Box
            id="calculator_landing_footer"
            sx={{
                width: "100%",
                background: theme.palette.funding_calculator.landing_page.background,
                fontFamily: "Manrope, sans-serif",
                position: "sticky",
                bottom: 0,
                zIndex: 500,
                m: "0.75rem"
            }}
        >
            <Box
                sx={{
                    width: "calc(100% - 1.25rem)",
                    height: isSmallDevice ? "50rem" : "auto",
                    position: "relative",
                    overflow: "hidden",
                    paddingBottom: isSmallDevice ? "0" : "37.07%",
                    backgroundImage: isSmallDevice ? `url(${footerBackgroundMobileImage})` : `url(${footerBackgroundImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: "1.5rem",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 15,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        px: "2rem",
                        pt: is1250x ? "1rem" : "5rem",
                        pb: "1rem",
                        color: "white"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%"
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: isSmallDevice ? "column" : "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                width: "100%"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    width: "80%"
                                }}
                            >
                                <Typography
                                    fontFamily={"Manrope, sans-serif"}
                                    fontSize={is1100x ? "2rem" : "3rem"}
                                    fontWeight={700}
                                    sx={{
                                        color: "#F6F8F9",
                                    }}
                                >
                                    Fuel your game's growth with non — dilutive, user acquisition financing
                                </Typography>
                                <Typography
                                    fontFamily={"Manrope, sans-serif"}
                                    fontSize={is1100x ? "0.8rem" : "1rem"}
                                    fontWeight={400}
                                    sx={{
                                        color: "#F6F8F9",
                                    }}
                                >
                                    Get the funds you need to boost growth with a simple credit line for user acquisition
                                </Typography>
                            </Box>
                            {isSmallDevice && (<Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    width: "100%",
                                    mt: "1rem"
                                }}
                            >
                                <Button
                                    sx={{
                                        my: isSmallDevice ? 0 : "1.5rem",
                                        mr: "0.5rem",
                                        maxWidth: "15rem",
                                        background: "#F6F8F9",
                                        borderRadius: "100px",
                                        padding: "14px 24px",
                                        color: "#101112",
                                        letterSpacing: "-3%",
                                        fontSize: isSmallDevice ? "0.8rem" : "1rem",
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        textTransform: "none",
                                        fontFamily: "Manrope, sans-serif",
                                        "&:hover": {
                                            backgroundColor: "#F6F8F9",
                                        },
                                    }}
                                >
                                    Contact Us
                                </Button>
                                <Button
                                    sx={{
                                        my: isSmallDevice ? 0 : "1.5rem",
                                        ml: "0.5rem",
                                        maxWidth: "15rem",
                                        background: "#F6F8F910",
                                        borderRadius: "100px",
                                        padding: "14px 24px",
                                        color: "#FFFFFF",
                                        letterSpacing: "-3%",
                                        fontSize: isSmallDevice ? "0.8rem" : "1rem",
                                        lineHeight: "15px",
                                        fontWeight: 600,
                                        textTransform: "none",
                                        fontFamily: "Manrope, sans-serif",
                                        "&:hover": {
                                            backgroundColor: "#F6F8F910",
                                        },
                                    }}
                                >
                                    Sign In
                                </Button>
                            </Box>)}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    width: isSmallDevice ? "100%" : "20%",
                                    marginTop: "1rem"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        width: "50%"
                                    }}
                                >
                                    <Typography
                                        fontFamily={"Manrope, sans-serif"}
                                        fontSize={"0.5rem"}
                                        fontWeight={700}
                                        sx={{
                                            color: "#989CA2",
                                        }}
                                    >
                                        FUNDING
                                    </Typography>
                                    <Typography
                                        fontFamily={"Manrope, sans-serif"}
                                        fontSize={"0.9rem"}
                                        fontWeight={700}
                                        sx={{
                                            color: "#F6F8F9",
                                            my: "0.5rem",
                                        }}
                                    >
                                        PvX Capital
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        width: "50%"
                                    }}
                                >
                                    <Typography
                                        fontFamily={"Manrope, sans-serif"}
                                        fontSize={"0.5rem"}
                                        fontWeight={700}
                                        sx={{
                                            color: "#989CA2",
                                        }}
                                    >
                                        COMPANY
                                    </Typography>
                                    <Typography
                                        fontFamily={"Manrope, sans-serif"}
                                        fontSize={"0.9rem"}
                                        fontWeight={700}
                                        sx={{
                                            color: "#F6F8F9",
                                            my: "0.5rem",
                                        }}
                                    >
                                        About Us
                                    </Typography>
                                    <Typography
                                        fontFamily={"Manrope, sans-serif"}
                                        fontSize={"0.9rem"}
                                        fontWeight={700}
                                        sx={{
                                            color: "#F6F8F9",
                                            my: "0.5rem",
                                        }}
                                    >
                                        Team
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {!isSmallDevice && (<Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                width: "100%"
                            }}
                        >
                            <Button
                                sx={{
                                    my: isSmallDevice ? 0 : "1.5rem",
                                    mr: "0.5rem",
                                    maxWidth: "15rem",
                                    background: "#F6F8F9",
                                    borderRadius: "100px",
                                    padding: "14px 24px",
                                    color: "#101112",
                                    letterSpacing: "-3%",
                                    fontSize: isSmallDevice ? "0.8rem" : "1rem",
                                    lineHeight: "15px",
                                    fontWeight: 600,
                                    textTransform: "none",
                                    fontFamily: "Manrope, sans-serif",
                                    "&:hover": {
                                        backgroundColor: "#F6F8F9",
                                    },
                                }}
                            >
                                Contact Us
                            </Button>
                            <Button
                                sx={{
                                    my: isSmallDevice ? 0 : "1.5rem",
                                    ml: "0.5rem",
                                    maxWidth: "15rem",
                                    background: "#F6F8F910",
                                    borderRadius: "100px",
                                    padding: "14px 24px",
                                    color: "#FFFFFF",
                                    letterSpacing: "-3%",
                                    fontSize: isSmallDevice ? "0.8rem" : "1rem",
                                    lineHeight: "15px",
                                    fontWeight: 600,
                                    textTransform: "none",
                                    fontFamily: "Manrope, sans-serif",
                                    "&:hover": {
                                        backgroundColor: "#F6F8F910",
                                    },
                                }}
                            >
                                Sign In
                            </Button>
                        </Box>)}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: isSmallDevice ? "column" : "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={500}
                                sx={{
                                    px: "0.5rem",
                                    color: "#F6F8F9",
                                }}
                            >
                                X
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={500}
                                sx={{
                                    px: "0.5rem",
                                    color: "#F6F8F9",
                                }}
                            >
                                Linkedin
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={500}
                                sx={{
                                    px: "0.5rem",
                                    color: "#F6F8F9",
                                }}
                            >
                                Facebook
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={500}
                                sx={{
                                    px: "0.5rem",
                                    color: "#F6F8F9",
                                }}
                            >
                                Instagram
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: isSmallDevice ? "flex-start" : "flex-end",
                            }}
                        >
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={700}
                                sx={{
                                    px: "0.5rem",
                                    color: "#989CA2B2",
                                    opacity: 0.7,
                                }}
                            >
                                © 2024 PVX INC.
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={700}
                                sx={{
                                    px: "0.5rem",
                                    color: "#989CA2B2",
                                    opacity: 0.7,
                                }}
                            >
                                PRIVACY POLICY
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={700}
                                sx={{
                                    px: "0.5rem",
                                    color: "#989CA2B2",
                                    opacity: 0.7,
                                }}
                            >
                                COOKIE POLICY
                            </Typography>
                            <Typography
                                fontFamily={"Manrope, sans-serif"}
                                fontSize={"0.7rem"}
                                fontWeight={700}
                                sx={{
                                    px: "0.5rem",
                                    color: "#989CA2B2",
                                    opacity: 0.7,
                                }}
                            >
                                TERMS OF SERVICE
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default CalculatorFooter;
