import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  UserListPage,
  DashboardPage,
  UserInvitePage,
  UserDetailsPage
} from '../pages';
import { NAV_ROUTES } from '../constants/navRoutes';
import { UserLayout } from '../layouts';

const PrivateRoutes: FC = () => (
  <Routes>
    <Route path={NAV_ROUTES.USER_MANAGEMENT} element={<UserLayout />}>
      <Route path={NAV_ROUTES.USER_MANAGEMENT} element={<UserListPage />} />
      <Route path={NAV_ROUTES.USER_MANAGEMENT_INVITE} element={<UserInvitePage />} />
      <Route path={NAV_ROUTES.USER_MANAGEMENT_DETAILS} element={<UserDetailsPage />} />
    </Route>
    <Route path={NAV_ROUTES.DASHBOARD} element={<DashboardPage />} />
    <Route path={NAV_ROUTES.DASHBOARD_MODULE} element={<DashboardPage />} />
    <Route path={NAV_ROUTES.DASHBOARD_MODULE_SUBMODULE} element={<DashboardPage />} />
    <Route path='*' element={<Navigate to={NAV_ROUTES.PAGE_NOT_FOUND} />} />
  </Routes>
);

export default PrivateRoutes;
