import { CHART_TAGS } from "../../constants/constants";

import {
  ChartDataSet,
  FormattedChartData,
  ChartTagType,
  LineChartUnitValue,
  DataPoint,
  BarChartUnitValue,
  DoughnutChartUnitValue,
  BarLineChartUnitValue,
} from "./types";

export const getChartColors = (type: ChartTagType, index: number) => {
  switch (type) {
    case CHART_TAGS.REVENUE: {
      if (index === 0) return { backgroundColor: "#80BEAF" };
      else return { backgroundColor: "#FF6347", borderColor: "#FF6347" };
    }
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_BALANCE_DELTA:
    case CHART_TAGS.PROFIT_BEFORE_TAXES_CUMULATIVE: {
      if (index === 0) {
        return {
          borderColor: "#84D6C2",
          backgroundColor: (context: any) => {
            if (!context.chart.chartArea) {
              return;
            }
            const {
              ctx,
              chartArea: { top, bottom },
            } = context.chart;
            const gradient = ctx.createLinearGradient(0, top, 0, bottom);
            gradient.addColorStop(0.3172, "rgba(124, 181, 167, 0.3)");
            gradient.addColorStop(0.6277, "rgba(124, 181, 167, 0)");
            return gradient;
          },
          fill: 1,
        };
      } else {
        return {
          borderColor: "#2E4A4FDD",
          backgroundColor: "#FAFAFA",
          fill: "origin",
        };
      }
    }
    case CHART_TAGS.PROFIT_BEFORE_TAXES:
    case CHART_TAGS.INTEREST_PAID_BAR:
    case CHART_TAGS.INTEREST_PAID_PIE: {
      if (index === 0) return { backgroundColor: "#80BEAF" };
      else return { backgroundColor: "#8892984D" };
    }
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE: {
      if (index === 0) return { backgroundColor: "#8892984D" };
      else return { backgroundColor: "#80BEAF" };
    }
    case CHART_TAGS.CUMULATIVE_ROAS: {
      return {
        borderColor: "#83D2BF",
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradient = ctx.createLinearGradient(0, top, 0, bottom);
          gradient.addColorStop(0.3976, "rgba(124, 181, 167, 0.3)");
          gradient.addColorStop(0.7262, "rgba(124, 181, 167, 0)");
          return gradient;
        },
        fill: "origin",
      };
    }
    default:
      return { borderColor: "#59C1E6", backgroundColor: "#59C1E6" };
  }
};

export const getBarChartStyleConfig = (type: ChartTagType, index: number) => {
  switch (type) {
    case CHART_TAGS.REVENUE: {
      return {
        maxBarThickness: 50,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0,
        },
      };
    }
    case CHART_TAGS.PROFIT_BEFORE_TAXES: {
      return {
        maxBarThickness: 30,
        borderRadius: {
          topLeft: 4,
          topRight: 4,
          bottomLeft: 0,
          bottomRight: 0,
        },
      };
    }
    case CHART_TAGS.INTEREST_PAID_BAR:
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE: {
      if (index === 0) return { maxBarThickness: 50, borderRadius: undefined };
      else
        return {
          maxBarThickness: 50,
          borderRadius: {
            topLeft: 4,
            topRight: 4,
            bottomLeft: 0,
            bottomRight: 0,
          },
        };
    }
    default:
      return { maxBarThickness: 50, borderRadius: undefined };
  }
};

export const getFormattedChartData = (
  type: ChartTagType,
  chartDataSet: ChartDataSet
): FormattedChartData | null => {
  switch (type) {
    case CHART_TAGS.CUMULATIVE_ROAS:
      {
        if (chartDataSet.length) {
          const labels: string[] = chartDataSet[0].data.map(
            (item: DataPoint) => `${item.x}`
          );
          const datasets = chartDataSet.map((curveData, index) => {
            const amplitudes: number[] = curveData.data.map(
              (item: DataPoint) => item.y
            );
            const { borderColor, backgroundColor, fill } = getChartColors(
              type,
              index
            );
            const dataset: LineChartUnitValue = {
              label: curveData.label,
              data: amplitudes,
              borderColor,
              backgroundColor,
              fill,
              tension: 0.5,
            };
  
            return dataset;
          });
  
          return { labels, datasets };
        }
  
        return null;
      }
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_BALANCE_DELTA:
    case CHART_TAGS.PROFIT_BEFORE_TAXES_CUMULATIVE: 
    {
      if (chartDataSet.length) {
        const labels: string[] = chartDataSet[0].data.map(
          (item: DataPoint) => `${item.x}`
        );
        const datasets = chartDataSet.map((curveData, index) => {
          const amplitudes: number[] = curveData.data.map(
            (item: DataPoint) => item.y
          );
          const { borderColor, backgroundColor, fill } = getChartColors(
            type,
            index
          );
          const dataset: LineChartUnitValue = {
            label: curveData.label,
            data: amplitudes,
            borderColor,
            backgroundColor,
            fill,
            tension: 0.5,
            borderDash: index === 0 ? undefined : [10,5]
          };

          return dataset;
        });

        return { labels, datasets };
      }

      return null;
    }
    case CHART_TAGS.REVENUE: {
      if (chartDataSet.length) {
        const labels: string[] = chartDataSet[0].data.map(
          (item: DataPoint) => `${item.x}`
        );
        const datasets = chartDataSet.map((curveData, index) => {
          const amplitudes: number[] = curveData.data.map(
            (item: DataPoint) => item.y
          );
          const { borderColor, backgroundColor } = getChartColors(type, index);
          const { maxBarThickness, borderRadius } = getBarChartStyleConfig(
            type,
            index
          );
          const dataset: BarLineChartUnitValue = {
            label: curveData.label,
            data: amplitudes,
            borderColor,
            backgroundColor,
            order: index === 0 ? 1 : 0,
            type: index === 0 ? "bar" : "line",
            barThickness: "flex",
            maxBarThickness,
            borderRadius,
            pointRadius: index === 0 ? undefined : 0,
            pointHoverRadius: index === 0 ? undefined : 0,
          };
          return dataset;
        });

        return { labels, datasets };
      }

      return null;
    }
    case CHART_TAGS.PROFIT_BEFORE_TAXES:
    case CHART_TAGS.MONTHLY_MARKETING_SPENDS_SURPLUS_EXPENDITURE:
    case CHART_TAGS.INTEREST_PAID_BAR: {
      if (chartDataSet.length) {
        const labels: string[] = chartDataSet[0].data.map(
          (item: DataPoint) => `${item.x}`
        );
        const datasets = chartDataSet.map((curveData, index) => {
          const amplitudes: number[] = curveData.data.map(
            (item: DataPoint) => item.y
          );
          const { backgroundColor } = getChartColors(type, index);
          const { maxBarThickness, borderRadius } = getBarChartStyleConfig(
            type,
            index
          );
          const dataset: BarChartUnitValue = {
            label: curveData.label,
            data: amplitudes,
            backgroundColor,
            barThickness: "flex",
            maxBarThickness: maxBarThickness,
            borderRadius,
          };
          return dataset;
        });
        return { labels, datasets };
      }
      return null;
    }
    case CHART_TAGS.INTEREST_PAID_PIE: {
      if (chartDataSet.length) {
        const sectorData = chartDataSet.map((curveData, index) => {
          const curvePoints: number[] = curveData.data.map(
            (item: DataPoint) => item.y
          );
          const amplitude: number = curvePoints.reduce(
            (sum, current) => sum + current,
            0
          );
          const { backgroundColor } = getChartColors(type, index);
          return {
            label: curveData.label,
            data: amplitude,
            backgroundColor,
          };
        });

        const labels = sectorData.map((sec) => sec.label);
        const datasets: DoughnutChartUnitValue[] = [
          {
            data: sectorData.map((sec) => sec.data),
            backgroundColor: sectorData.map((sec) => sec.backgroundColor),
          },
        ];

        return { labels, datasets };
      }
      return null;
    }
    default:
      return null;
  }
};
