import { createSlice } from '@reduxjs/toolkit';
import { UserListState } from './types/userList';
import { User } from '../../stores/User/types/user';

const initialState: UserListState = {
  userList: [],
  totalCount: 0,
  allUserCount: 0,
  page: 0,
  pageSize: 0, 
};

export const UserListData = createSlice({
  name: 'UserList',
  initialState,
  reducers: {
    setUserList: (state: UserListState, { payload }: { payload: { items: User[], totalElements: number, totalPages: number, isSearch: boolean} }) => {
      return {
        ...state,
        userList: payload.items,
        totalCount: payload.totalElements,
        allUserCount: payload.isSearch ? state.allUserCount : payload.totalElements,
      };
    },
    resetUserListData: () => initialState
  }
});

export const {
  setUserList,
  resetUserListData,
} = UserListData.actions;

export default UserListData.reducer;
