import apiRoutes from '../constants/apiRoutes';
import baseApi from '../services/api';
import { UserPayload } from './company';

const userList = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<any, any>({
      query: ({ pageNumber, pageSize, search, sort, sortOrder }) => {
        let baseEndpoint = `${apiRoutes.users}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
        if (search) {
          baseEndpoint += `&search=${search}`;
        }
        if (sort) {
          baseEndpoint += `&sort=${sort}&sortOrder=${sortOrder || 'ASC'}`;
        }

        return baseEndpoint;
      }
    }),
    getUserById: builder.query<any, string>({
      query: (id) => apiRoutes.userDetailsbyId(id)
    }),
    inviteUser: builder.mutation<any, any>({
      query: (payload: UserPayload) => ({
        url: `${apiRoutes.users}`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['userList']
    }),
    updateUser: builder.mutation<any, any>({
      query: (payload: UserPayload) => ({
        url: `${apiRoutes.users}/${payload.id}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['userList']
    }),
    deleteUser: builder.mutation<any, any>({
      query: (payload: { id: string; }) => ({
        url: `${apiRoutes.users}/${payload.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['userList']
    }),
    retryCreateUser: builder.mutation<any, any>({
      query: (payload: { id: string; }) => ({
        url: `${apiRoutes.users}/${payload.id}`,
        method: 'POST'
      }),
      invalidatesTags: ['userList']
    })
  })
});

export const {
  useLazyGetUsersQuery,
  useInviteUserMutation,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useRetryCreateUserMutation
} = userList;
