import { useCreateCompanyMutation } from '../../api/company';
import useCompany from './useCompany';

const useCreateCompany = () => {
  const [createCompany] = useCreateCompanyMutation();
  const { getCompanyList } = useCompany();

  const addCompany = async (payload: { name: string }) => {
    try {
      const response = await createCompany(payload).unwrap();

      if (response?.data) {
        getCompanyList();
        return response.data;
      }
    } catch (err) {
      console.error('Error while creating company :', err);
    }
  };

  return { addCompany };
};

export default useCreateCompany;
