import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SelectWithLabel from "../../../components/SelectWithLabel/SelectWithLabel";
import DrawerComponent from "../../../components/Drawer/Drawer";
import FilterSection from "./FilterSection";
import { RootState } from "../../../stores/store";
import { UserSubmoduleMap } from "../../../api/company";
import { SubmoduleFilterMap } from "../../../stores/Modules/types/modules";
import { setSelectedSubmoduleFilters } from "../../../../src/stores/Modules/slice";
import { getSelectedFilterIds } from "../../../utils/common";

type EachModuleType = {
  item: UserSubmoduleMap;
  index: number;
  onDelete: MouseEventHandler;
  onUpdateData: any;
  handleUpdatedSubModuleIds: any;
};
const ModuleRow: FC<EachModuleType> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch()
  const { item, index, onDelete, onUpdateData, handleUpdatedSubModuleIds } = props;
  const modules = useSelector((state: RootState) => state?.ModulesData);

  const [openDrawer, toggleOpenDrawer] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState(item.moduleId || '');
  const [selectedSubModule, setSelectedSubModule] = useState(item.submoduleId || '');
  const [checkedFilters, setCheckedFilters] = useState<string[]>([]);
  const [isTouched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    onUpdateData({
      moduleId: selectedModule,
      submoduleId: selectedSubModule,
      filterIds: item.filterIds,
    });
  }, [selectedSubModule, item.filterIds]);

  useEffect(() => {
    const selectedFilters = modules?.modules?.length ? getSelectedFilterIds(modules.modules, item.submoduleId) : [];
    const editFlowFilters = item.filterIds || [];

    if (isTouched) {
      setCheckedFilters(selectedFilters);
      handleUpdatedSubModuleIds(item.submoduleId)
    } else {
      setCheckedFilters(editFlowFilters);
    }
  }, [modules]);

  const handleFilterSave = (selectedFilters: any) => {
    setTouched(true);
    dispatch(setSelectedSubmoduleFilters({
      moduleName: modules?.modules.find((m) => m.id === selectedModule)?.name || '',
      subModuleName: modules?.modules.find((m) => m.id === selectedModule)?.submodules?.find((s) => s.id === selectedSubModule)?.name || '',
      filterOptions: selectedFilters
    }))

  }

  const mapModuleNamesAndIds = (): { label: string, value: string }[] => {
    if (modules?.modules.length) {
      return modules.modules.map((module) => ({
        label: module.name,
        value: module.id
      }));
    } else {
      return [];
    }
  };

  const mapSubmoduleNamesAndIds = (): { label: string, value: string }[] => {
    if (modules?.modules?.length && selectedModule) {
      const currentModule = modules.modules.find(module => module.id === selectedModule);
      if (!currentModule?.submodules) {
        return [];
      }
      return currentModule.submodules.map(submodule => ({
        label: submodule.name,
        value: submodule.id
      }));
    }
    return [];
  };

  const mapSubmoduleFilterNamesAndIds = () => {
    const currentModule = modules.modules.find(module => module.id === selectedModule);
    const currentSubmodule = currentModule?.submodules?.find(sub => sub.id === selectedSubModule);

    if (currentSubmodule) {
      const filterOptions = currentSubmodule.filters?.reduce((acc, item) => {
        const { submoduleFilterName } = item;

        if (!acc[submoduleFilterName]) {
          acc[submoduleFilterName] = [];
        }

        acc[submoduleFilterName].push(item);
        return acc;
      }, {} as SubmoduleFilterMap);

      return filterOptions || null;
    }
    return null;
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr 1fr 1fr"}
      width="100%"
      gap={isMobile ? "16px" : "24px"}
      alignItems="center"
    >
      <SelectWithLabel
        label="Module"
        value={selectedModule}
        labelId={`module-selection-${index}`}
        defaultValue={item.moduleId}
        menuItems={mapModuleNamesAndIds()}
        onChange={(val) => {
          if (selectedSubModule) {
            setSelectedSubModule('');
          }
          setSelectedModule(val);
        }}
      />
      <SelectWithLabel
        label="Sub-module"
        value={selectedSubModule}
        labelId={`sub-module-selection-${index}`}
        defaultValue={item.submoduleId}
        menuItems={mapSubmoduleNamesAndIds()}
        onChange={(val) => setSelectedSubModule(val)}
        disabled={!Boolean(selectedModule)}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isMobile ? "space-between" : "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          onClick={Object.keys(mapSubmoduleFilterNamesAndIds() || {})?.length ? () => toggleOpenDrawer(true) : () => { }}
          sx={{
            border: "1px solid #00000040",
            borderRadius: "4px",
            height: "100%",
            alignItems: "center",
            px: 1,
            color: "#00000080",
            opacity: Object.keys(mapSubmoduleFilterNamesAndIds() || {})?.length ? "1" : "0.5",
            cursor: Object.keys(mapSubmoduleFilterNamesAndIds() || {})?.length ? "pointer" : "not-allowed",
            minWidth: isMobile ? "150px" : "200px"
          }}
        >
          {checkedFilters.length > 0 ? (
            <Typography
              sx={{
                color: "#000000",
                ml: 1
              }}
            >
              {checkedFilters.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{
                ml: 1
              }}
            >
              Filters
            </Typography>
          )}
          <ArrowRightIcon sx={{ color: "#49454F" }} />
        </Box>
        <Box
          sx={{
            height: 40,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: isMobile ? 3 : 20,
            backgroundColor: "transparent",
            ml: isMobile ? 0 : 4
          }}
        >
          <DeleteOutlineIcon
            htmlColor="#2F736E"
            sx={{
              height: '100%',
              width: '100%',
              cursor: "pointer",
              backgroundColor: "#e0e0e0",
              borderRadius: isMobile ? 3 : 20,
              padding: '8px',
              background: 'transparent'
            }}
            onClick={onDelete}
          />
        </Box>
      </Box>
      <DrawerComponent
        open={openDrawer}
        anchor="right"
        close={(e) => {
          toggleOpenDrawer(false);
        }}
        height='98vh'
        width={isMobile ? '100%' : '25%'}
        zIndex={9999}
      >
        <FilterSection
          moduleName={modules?.modules.find((m) => m.id === selectedModule)?.name || ''}
          moduleId={selectedModule || ''}
          subModuleId={selectedSubModule || ''}
          filterMap={mapSubmoduleFilterNamesAndIds()}
          close={() => toggleOpenDrawer(false)}
          handleFilterSave={handleFilterSave}
          selectedFilters={checkedFilters}
        />
      </DrawerComponent>
    </Box>
  );
};

export default ModuleRow;
