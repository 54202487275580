import { FC } from 'react';
import { useTheme, TextField, TextFieldVariants, Typography, Box, useMediaQuery } from '@mui/material';
import { formatCurrency } from '../../../utils/common';

type CurrencyInputFieldPropType = {
    id?: string;
    label: string;
    variant?: TextFieldVariants;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    error?: boolean;
    errorText?: string;
    disabled?: boolean;
};

const CurrencyInputField: FC<CurrencyInputFieldPropType> = (props) => {
    const {
        id,
        label,
        variant = 'outlined',
        value,
        onChange,
        error = false,
        errorText = '',
        disabled = false
    } = props;
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

    const computedStatusColor = () => {
        if (disabled) {
            return theme.palette.funding_calculator.main_page.font;
        } else if (error) {
            return "#BA1A1A";
        } else {
            return "#F6F8F9";
        }
    }

    return (
        <>
            <Box
                id={`${label}-currency-input-field`}
                sx={{
                    width: "100%",
                    height: isSmallDevice ? "5rem" : "max-content",
                    display: "flex",
                    flexDirection: "column",
                    py: "0.9rem",
                    px: "0.1rem",
                    background: error ? "#FFD2CC" : "#E6FFF810",
                    borderRadius: "1rem",
                    position: "relative",
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        borderRadius: "inherit",
                        padding: "1px",
                        background: "linear-gradient(to top right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(110, 119, 119, 1))",
                        WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        maskComposite: "exclude",
                        WebkitMaskComposite: "xor",
                    },
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        fontWeight: 600,
                        fontSize: '0.6rem',
                        color: error ? "#BA1A1A" : "#989CA2",
                        px: "1rem",
                        pb: "0.5rem"
                    }}
                    fontFamily="Manrope, sans-serif"
                >
                    {label.toUpperCase()}
                </Typography>
                <TextField
                    id={id || label}
                    variant={variant}
                    InputLabelProps={{ style: { color: error ? theme.palette.error.main : '' } }}
                    sx={{
                        width: '100%',
                        border: 'none',
                        input: {
                            fontSize: '0.8rem',
                            color: computedStatusColor(),
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            paddingTop: '0',
                            paddingBottom: '0',
                        },
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            borderColor: computedStatusColor(),
                            '& fieldset': {
                                border: 'none',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                    disabled={disabled}
                    value={formatCurrency(value)}
                    onChange={onChange}
                />
            </Box>
            {(error)
                ? (
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 400,
                            fontSize: '0.7rem',
                            color: "#FFD2CC",
                            px: "1rem",
                            pt: "0.2rem"
                        }}
                        fontFamily="Manrope, sans-serif"
                    >
                        {errorText}
                    </Typography>
                )
                : <></>}
        </>
    );
}

export default CurrencyInputField;
