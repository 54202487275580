import React from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ExpandLess';
import ArrowDownwardIcon from '@mui/icons-material/ExpandMore';

interface SortButtonGroupProps {
  columnId: string;
  sortOrder: "ASC" | "DESC" | null;
  onSort: (columnId: string, sortOrder: "ASC" | "DESC") => void;
}

const SortButtonGroup: React.FC<SortButtonGroupProps> = ({ columnId, sortOrder, onSort }) => {
  const handleSort = (order: "ASC" | "DESC") => {
    onSort(columnId, order);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ height: "100%", mx: 2 }}>
      <IconButton
        sx={{
          height: "8px",
          width: "8px",
          padding: 0,
          color: sortOrder === "ASC" ? "#162C36" : "#17191C59",
        }}
        onClick={() => handleSort("ASC")}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Box sx={{ height: "4px" }} />
      <IconButton
        sx={{
          height: "8px",
          width: "8px",
          padding: 0,
          color: sortOrder === "DESC" ? "#162C36" : "#17191C59",
        }}
        onClick={() => handleSort("DESC")}
      >
        <ArrowDownwardIcon />
      </IconButton>
    </Box>
  );
};

export default SortButtonGroup;
