import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, TextField, Box, Button, Typography, useMediaQuery } from '@mui/material';
import useCalculationIdByEmail from '../../../hooks/api/useCalculationIdByEmail';
import useDebounce from '../../../hooks/useDebounce';
import { NAV_ROUTES } from '../../../constants/navRoutes';

const FundingEmailPopover: FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const { getCalculationIdByEmail } = useCalculationIdByEmail();

    const handleProceed = async () => {
        const res = await getCalculationIdByEmail(email, true);

        if (res?.data?.requestId) {
            navigate(`${NAV_ROUTES.FUNDING_CALCULATOR_DETAILS}/${res.data.requestId}`);
        } else {
            setError(true)
            setEmail('');
        }
    }

    const debouncedHandleProceed = useDebounce(handleProceed, 1000);

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#00000095",
                zIndex: 9999,
            }}
        >
            <Box
                sx={{
                    backgroundColor: "#ECE6F0",
                    borderRadius: "28px",
                    height: "max-content",
                    p: isMobile ? "24px" : "2rem",
                    width: isMobile ? "fit-content" : "100%",
                    maxWidth: isMobile ? "300px" : "500px",
                    position: "relative",
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center'
                }}
            >
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        lineHeight: '2rem',
                        textAlign: 'center',
                        mb: '1rem'
                    }}
                >
                    {error ? 'Please enter a valid email-id!' : 'Please enter your registered email-id to proceed!'}
                </Typography>
                <TextField
                    variant='outlined'
                    sx={{
                        width: '100%',
                        my: isMobile ? 0 : '1rem'
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: '1rem',
                        mt: '1rem',
                        mx: '1rem'
                    }}
                    onClick={debouncedHandleProceed}
                >
                    Proceed
                </Button>

            </Box>
        </Box>
    );
}

export default FundingEmailPopover;
