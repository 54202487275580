import { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJs } from "chart.js";
import { Box, Typography } from "@mui/material";
import { getChartConfig } from "../chartConfigs";
import { getFormattedChartData } from "../utils";
import { BarChartUnitValue, ChartConfigType, ChartProps } from "../types";
import BarChartSkelton from "./BarChartSkelton";
import ErrorChart from '../../../assets/images/error-chart.svg';
import { genericTexts } from "@utils/translations/en";


ChartJs.register(BarElement, CategoryScale);


const BarChart: React.FC<ChartProps> = ({ type, data, isLoading, error, metadata }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [chartNode, setChartNode] = useState<HTMLDivElement | null>(null); 

    // Callback ref to assign the ref value dynamically
    const chartRef = useCallback((node: HTMLDivElement | null) => {
        setChartNode(node); // Save the DOM node in state
    }, []);

    // Intersection Observer setup
    useEffect(() => {
        if (chartNode) {
            // Check if the chart is already visible in the viewport on mount
            const initialEntry = chartNode.getBoundingClientRect();
            if (initialEntry.top >= 0 && initialEntry.bottom <= window.innerHeight) {
                setIsVisible(true);
            } else {
                const observer = new IntersectionObserver(
                    (entries) => {
                        const entry = entries[0];
                        setIsVisible(entry.isIntersecting);
                    },
                    { threshold: 0.1 }
                );
                observer.observe(chartNode);

                // Cleanup observer on unmount
                return () => {
                    observer.unobserve(chartNode);
                };
            }
        }
    }, [chartNode]);


    if ((data && !data?.length) || isLoading) return <BarChartSkelton />;

    if (data) {
        const chartData = getFormattedChartData(type, data);
        const chartConfig: ChartConfigType | null = getChartConfig(type, data, metadata);

        if (chartData && chartConfig) {
            return (
                <Box ref={chartRef} sx={{ height: '100%', width: '100%' }}>
                    {/* Conditionally render the chart when it is visible */}
                    {isVisible && (
                        <Bar
                            data={{
                                labels: chartData.labels,
                                datasets: chartData.datasets as BarChartUnitValue[]
                            }}
                            options={chartConfig.options}
                            plugins={chartConfig.plugins}
                        />
                    )}
                   
                </Box>
            );
        } else {
            return <BarChartSkelton />;
        }
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={ErrorChart}
                    alt={genericTexts.refreshChart}
                    style={{
                        width: '15rem',
                        height: 'auto',
                        marginBottom: 20
                    }}
                />
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {genericTexts.oopsError}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>
                    {genericTexts.refreshChart}
                </Typography>
            </Box>
        );
    }
};

export default BarChart;
