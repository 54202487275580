import { useDispatch } from 'react-redux';
import { UpdateFundingCalculationPayload, useUpdateFundingPlaygroundMutation } from '../../api/fundingCalculator';
import { setFundingCalculator } from '../../stores/FundingCalculator/slices';
import { convertToFundingCalculations } from '../../utils/common';

const useUpdateFundingPlayground = () => {
  const [updateFundingPlayground] = useUpdateFundingPlaygroundMutation();

  const dispatch = useDispatch();

  const updatePlaygroundCalculation = async (payload: UpdateFundingCalculationPayload) => {
    try {
      const response = await updateFundingPlayground(payload);

      if (response?.data?.data) {
        const calculation = convertToFundingCalculations(response.data.data);
        dispatch(setFundingCalculator(calculation));

        return calculation;
      }
    } catch (err) {
      console.error('Error while updating funding playground :', err);
    }
  };

  return { updatePlaygroundCalculation };
};

export default useUpdateFundingPlayground;
