import { Drawer } from "@mui/material";
import { FC, ReactNode } from "react";

type DrawerProps = {
  height?: string;
  width?: string;
  zIndex?: number;
  anchor: "left" | "right" | "top" | "bottom";
  open?: boolean;
  close: (s: string) => void;
  children: ReactNode;
};
const DrawerComponent: FC<DrawerProps> = (props) => {
  const { anchor, open = false, close, children } = props;
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={close}
      sx={{
        height: `${props.height}` || '98vh',
        width: `${props.width}` || '100%',
        zIndex: props.zIndex
      }}
      PaperProps={{
        sx: {
          height: `${props.height}` || '98vh',
          my: '1vh',
          mr: '1vh',
          borderRadius: "1rem"
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
