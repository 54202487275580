import { useLazyGetGuestTokenQuery } from '../../api/userModules';

const useGuestToken = () => {
    const [getGuestToken, { isLoading, isError }] = useLazyGetGuestTokenQuery();

    const fetchGuestToken = async (submoduleId: string) => {
        try {
            const response = await getGuestToken(submoduleId).unwrap();
            if (response?.data) {
                return response.data;
            }
        } catch (err) {
            console.error(err);
        }
        return null;
    };

    return { fetchGuestToken, isLoading, isError };
};

export default useGuestToken;
