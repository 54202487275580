/* eslint-disable import/no-anonymous-default-export */
export default {
  userDetails: '/users/me',
  userDetailsbyId: (id: string) => `/users/${id}`,
  userModulesById: (id: string) => `/visualization/users/${id}/details`,
  userModules: '/visualization/modules',
  userSubmodules: (moduleId: string) => `/visualization/modules/${moduleId}/submodules`,
  userSubmoduleFilters: (submoduleId: string) => `/visualization/submodules/${submoduleId}/filters`,
  userSubmoduleFiltersValues: (submoduleId: string, filterId: string) => `/visualization/submodules/${submoduleId}/filters/${filterId}/values`,
  userGuestToken: (submoduleId: string) => `/visualization/submodules/${submoduleId}/token`,
  users: '/users',
  companies: '/companies',
  fundingCalculator: 'lead-magnet',
  fundingCalculatorDeatils: (id: string) => `/lead-magnet/${id}`,
};
