import React from 'react';
import { Box, Skeleton } from '@mui/material';

const BarChart: React.FC = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxHeight: '18rem' }}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                px: '1rem',
                py: '0.25rem'
            }}
        >
            {/* y axis values */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                {Array.from(new Array(9)).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="circular"
                        width={'1rem'}
                        height={'0.5rem'}
                        sx={{ borderRadius: '50%', mt: '1rem' }}
                    />
                ))}
            </Box>
            <Box sx={{ width: '100%', mx: '1rem' }}>
                {/* Main rectangular skeleton */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        mt: '1rem',
                    }}
                >
                    {Array.from(new Array(12)).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            width="3rem"
                            height="12rem"
                            sx={{ borderRadius: '0.25rem' }}
                        />
                    ))}
                </Box>
                {/* x axis values */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                        mt: '1rem',
                    }}
                >
                    {Array.from(new Array(12)).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="circular"
                            width="1rem"
                            height="0.5rem"
                            sx={{ borderRadius: '50%' }}
                        />
                    ))}
                </Box>
                {/* x axis label */}
                {/* <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        mt: '1rem',
                    }}
                >
                    <Skeleton
                        variant="rectangular"
                        width={'10rem'}
                        height={'1rem'}
                        sx={{ borderRadius: '10%', mt: '1rem' }}
                    />
                </Box> */}
            </Box>
        </Box>
        {/* Legend */}
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                px: '1rem',
                py: '1rem'
            }}
        >
            <Skeleton
                variant="circular"
                width="1rem"
                height="1rem"
                sx={{ borderRadius: '50%', mr: '1rem' }}
            />
            <Skeleton
                variant="rectangular"
                width="5rem"
                height="1rem"
                sx={{ borderRadius: '8px' }}
            />
            <Skeleton
                variant="circular"
                width="1rem"
                height="1rem"
                sx={{ borderRadius: '50%', ml: '1rem', mr: '1rem' }}
            />
            <Skeleton
                variant="rectangular"
                width="5rem"
                height="1rem"
                sx={{ borderRadius: '8px' }}
            />
        </Box>
    </Box>
);

export default BarChart;
