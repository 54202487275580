import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import backgroundImage from '../../assets/images/v2/pvx-home-bg.png';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { genericTexts, titles } from '@utils/translations/en';

const PageNotFound: FC = () => {
  const navigate = useNavigate();

  // Hook to redirect to home page on invalid path
  useEffect(() => {
    setTimeout(() => {
      navigate(NAV_ROUTES.HOME);
    }, 10000);
  }, [navigate]);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#FFFFFF',
          textAlign: 'center'
        }}
      >
        <div>
          <h1>{titles.PAGE_NOT_FOUND}</h1>
          <p>{genericTexts.redirecting}</p>
        </div>
      </Box>
    </>
  );
}

export default PageNotFound;
