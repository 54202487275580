import React, { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { Box, Typography } from '@mui/material';
import ErrorChart from '../../../assets/images/error-chart.svg';
import LineChartSkelton from './LineChartSkelton';
import { ChartProps, ChartConfigType, LineChartUnitValue } from '../types';
import { getFormattedChartData } from '../utils';
import { getChartConfig } from '../chartConfigs';
import { genericTexts } from '@utils/translations/en';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartAnnotation,
    Filler
  );

const LineChart: React.FC<ChartProps> = ({ type, data, metadata, isLoading, error }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [chartNode, setChartNode] = useState<HTMLDivElement | null>(null); 

    // Callback ref to assign the ref value dynamically
    const chartRef = useCallback((node: HTMLDivElement | null) => {
        setChartNode(node); // Save the DOM node in state
    }, []);

    // Intersection Observer setup
    useEffect(() => {
        if (chartNode) {
            // Check if the chart is already visible in the viewport on mount
            const initialEntry = chartNode.getBoundingClientRect();
            if (initialEntry.top >= 0 && initialEntry.bottom <= window.innerHeight) {
                setIsVisible(true);
            } else {
                const observer = new IntersectionObserver(
                    (entries) => {
                        const entry = entries[0];
                        setIsVisible(entry.isIntersecting);
                    },
                    { threshold: 0.1 }
                );
                observer.observe(chartNode);

                // Cleanup observer on unmount
                return () => {
                    observer.unobserve(chartNode);
                };
            }
        }
    }, [chartNode]);

    if ((data && !data?.length) || isLoading) return <LineChartSkelton />;

    if (data) {
        const chartData = getFormattedChartData(type, data);
        const chartConfig: ChartConfigType | null = getChartConfig(type, data, metadata);

        if (chartData && chartConfig) {
            return (
                <Box ref={chartRef} sx={{ height: '100%', width: '100%' }}>
                    {/* Conditionally render the chart when it is visible */}
                    {isVisible && (
                        <Line
                            data={{
                                labels: chartData.labels,
                                datasets: chartData.datasets as LineChartUnitValue[],
                            }}
                            options={chartConfig.options}
                            plugins={chartConfig.plugins}
                        />
                    )}
                </Box>
            );
        } else {
            return <LineChartSkelton />;
        }
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={ErrorChart}
                    alt={genericTexts.refreshChart}
                    style={{
                        width: '15rem',
                        height: 'auto',
                        marginBottom: 20
                    }}
                />
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {genericTexts.oopsError}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>
                    {genericTexts.refreshChart}
                </Typography>
            </Box>
        );
    }
};

export default LineChart;
