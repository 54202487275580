import React, { FC, useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { useSelector } from "react-redux";
import { RootState } from "@stores/store";
import { formatCurrency } from "@utils/common";
import { useTheme, useMediaQuery } from "@mui/material";

Chart.register(ArcElement);

const CalculatorSummaryArc: FC<{ redrawCount: number }> = ({ redrawCount }) => {
  const theme = useTheme();
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const reducedWidth = isMediumDevice ? (isSmallDevice ? "17rem" : "15.3rem") : "17rem";
  const reducedHeight = isMediumDevice ? (isSmallDevice ? "17.5rem" : "14.85rem") : "16.5rem";
  const reducedLeft = isMediumDevice ? (isSmallDevice ? "-12.5%" : "16%") : 0;
  const redrawCountRef = useRef(0);

  const [shouldReDraw, setReDraw] = useState(false);

  const fundingCalculatorData = useSelector(
    (state: RootState) => state?.FundingCalculatorData
  );

  useEffect(() => {
    if (!shouldReDraw && redrawCount > 0 && redrawCount !== redrawCountRef.current) {
      redrawCountRef.current = redrawCount;
      setReDraw(true);
    }
    setTimeout(() => setReDraw(false), 1);
  }, [redrawCount, shouldReDraw]);

  const { eligibleFund, annualFacilitySize } =
    fundingCalculatorData.fundingCalculations.summary || {};

  const pvxFunding = eligibleFund;
  const companyFunding = annualFacilitySize - eligibleFund;

  // Data for the doughnut chart
  const data = {
    labels: ["PvX Funding", "Company Funding"],
    datasets: [
      {
        data: [pvxFunding, companyFunding],
        backgroundColor: ["#5DD1B5", "#2F736E"],
        hoverBackgroundColor: ["#5DD1B5", "#2F736E"],
        borderWidth: 0,
        cutout: "82%", // Thickness of the chart's arc
        borderRadius: 20, // Makes the ends of the arc rounded
        spacing: 5, // Space between segments
      },
    ],
  };

  // Chart options for styling
  const options = {
    rotation: -105,
    circumference: 210,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false, // Hides the default chart legend
      },
      tooltip: {
        enabled: false, // Disables tooltips to match the design
      },
    },
  };

  // Custom plugin for drawing the inner half-circle border
  const plugins = [
    {
      id: "customBorder",
      afterDraw: (chart: any) => {
        const {
          ctx,
          chartArea: { width, height },
        } = chart;

        // Calculate the radius based on the cutout percentage
        const radius = (Math.min(width, height) / 2) * 0.8 - 5;
        const centerX = width / 2;
        const centerY = height / 2 + 50;

        // Adjust the start and end angles based on the chart's rotation (-105 degrees) and circumference (210 degrees)
        const startAngle = (-195 * Math.PI) / 180; // Start angle (-105° in radians)
        const endAngle = startAngle + (210 * Math.PI) / 180; // 210° to match the chart's circumference

        // Drawing the half-circle border
        ctx.save();
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.strokeStyle = "#F6F8F926"; // Your desired border color
        ctx.lineWidth = 1; // Adjust thickness if needed
        ctx.stroke();
        ctx.restore();
      },
    },
  ];

  const getFundingInMillions = (amount: number) => {
    return (Math.floor(amount / 100000) / 10).toLocaleString(
      "en-US",
      {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }
    )
  }

  return (
    <div
      style={{
        width: isSmallDevice ? "20rem" : "28.875rem",
        height: isSmallDevice ? "20rem" : "23rem",
        background: "linear-gradient(69.42deg, #181A1C 9.31%, #222D2E 88.54%)",
        borderRadius: "20px",
        padding: "30px 50px",
        position: "relative",
        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {/* Text content in the center of the chart */}
      <div
        style={{
          position: "absolute",
          top: isMediumDevice ? "30%" : "35%",
          left: "50%",
          transform: "translate(-52%, 35%)",
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "0.875rem",
            color: "#F6F8F980",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 550,
            letterSpacing: "-0.03rem",
          }}
        >
          User Acquisition Spend
        </div>
        <div
          style={{
            fontSize: "1.75rem",
            color: "#FFFFFF",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 550,
            letterSpacing: "-0.100rem",
          }}
        >
          {formatCurrency(annualFacilitySize, true)}
        </div>
        <div
          style={{
            fontSize: "0.875rem",
            color: "#F6F8F9",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 550,
            letterSpacing: "-0.03rem",
          }}
        >
          {fundingCalculatorData.fundingCalculations.variables.paybackPeriodInMonths ? `Over ${fundingCalculatorData.fundingCalculations.variables.paybackPeriodInMonths} months` : ''}
        </div>
      </div>

      {/* Doughnut chart */}
      <div
        style={{
          position: "relative",
          width: reducedWidth,
          height: reducedHeight,
          left: reducedLeft,
        }}
      >
        <Doughnut data={data} options={options} plugins={plugins} redraw={shouldReDraw} />
      </div>

      {/* Legend below the chart */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: isMediumDevice ? "2rem" : 0
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            fontFamily: "Manrope, sans-serif",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#F6F8F980",
              letterSpacing: "-0.56px",
              fontWeight: 550,
            }}
          >
            PvX Funding
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: "#5DD1B5",
                borderRadius: "50%",
                flexShrink: 0,
              }}
            />
            <div
              style={{
                fontSize: "20px",
                marginLeft: "5px",
                color: "#FFFFFF",
                fontWeight: 600,
                letterSpacing: "-1px",
              }}
            >
              {`$${getFundingInMillions(pvxFunding)}M`}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "start",
            fontFamily: "Manrope, sans-serif",
            flexDirection: "column",
            gap: "0px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              color: "#F6F8F980",
              letterSpacing: "-0.56px",
              fontWeight: 550,
            }}
          >
            Company Funding
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "16px",
                height: "16px",
                backgroundColor: "#2F736E",
                borderRadius: "50%",
                flexShrink: 0,
              }}
            />
            <div
              style={{
                fontSize: "20px",
                marginLeft: "5px",
                color: "#FFFFFF",
                fontWeight: 600,
                letterSpacing: "-1px",
              }}
            >
              {`$${getFundingInMillions(companyFunding)}M`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorSummaryArc;
