import { useLazyGetCompaniesQuery } from '../../api/company';
import { useDispatch } from 'react-redux';
import { setCompanyList } from '../../stores/CompanyList/slice';

const useCompany = () => {
  const [getCompanies, { isLoading, isError }] = useLazyGetCompaniesQuery();

  const dispatch = useDispatch();

  const getCompanyList = async () => {
    try {
      const response = await getCompanies({}).unwrap();

      if (response.data) {
        dispatch(setCompanyList(response.data));
      }
    } catch (err) {
      console.error('Error while fetching companies :', err);
    }
  };

  return { getCompanyList, isLoading, isError };
};

export default useCompany;
