import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { messages } from "@utils/translations/en";
import CalculatorSummaryArc from "./CalculatorSummaryArc";
import FundingCalculatorSummary from "./FundingCalculatorSummary";
import CalculatorOutputIcon from '../../../assets/icons/v2/calculator-output-icon.svg'

interface FundingCalculatorSummaryWrapperProps {
  isChartLoading: boolean;
  toggleLoader: boolean;
  cashBalanceIncrease: string;
  redrawCount: number;
}

const FundingCalculatorSummaryWrapper: React.FC<
  FundingCalculatorSummaryWrapperProps
> = ({ isChartLoading, toggleLoader, cashBalanceIncrease, redrawCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "max-content",
            display: 'flex',
            justifyContent: "flex-start",
            alignItems: 'center',
            pt: '0.8rem',
            pb: '1.5rem',
            fontSize: '1rem',
            background: '#FFFFFF',
          }}
        >
          <img
            src={CalculatorOutputIcon}
            alt={"Calculator Output"}
            style={{
              width: '2rem',
              height: '2rem',
              marginRight: "1rem"
            }}
          />
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: 800,
              color: "#162936",
              fontSize: "1.5rem",
            }}
          >
            {isChartLoading ? "Calculating..." : "Calculator Output"}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <CalculatorSummaryArc redrawCount={redrawCount} />
          <FundingCalculatorSummary showLoader={toggleLoader} cashBalanceIncrease={cashBalanceIncrease} />
        </Box>
      </Box>
      <Typography
        sx={{
          mt: "1.5rem",
          fontSize: "0.65rem",
          fontWeight: 800,
          fontFamily: "Manrope, sans-serif",
          color: "#898C8D",
        }}
      >
        <b>{messages.fundingCalculatorLanding.disclaimer}</b>
        {messages.fundingCalculatorLanding.disclaimerMessage}
      </Typography>
    </>
  );
};

export default FundingCalculatorSummaryWrapper;
