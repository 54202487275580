import { useDispatch } from 'react-redux';
import { useLazyGetfundingCalculatorByIdQuery } from '../../api/fundingCalculator';
import { setFundingCalculator } from '../../stores/FundingCalculator/slices';
import { convertToFundingCalculations } from '../../utils/common';

const useCalculationById = () => {
  const [getfundingCalculatorById, { isLoading, isError }] = useLazyGetfundingCalculatorByIdQuery();
  const dispatch = useDispatch();

  const getCalculationsById = async (id: string) => {
    try {
      const response = await getfundingCalculatorById(id).unwrap();

      if (response.data) {
        const calculation = convertToFundingCalculations(response.data);
        dispatch(setFundingCalculator(calculation));

        return response.data;
      }
    } catch (err) {
      console.error('Error while fetching funding calculator by id :', err);
    }
  };

  return { getCalculationsById, isLoading, isError };
};

export default useCalculationById;
