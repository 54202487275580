import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const Dot = ({ id, color, delay, size }: { id: string, color: string, delay: number, size: number }) => (
    <Box
        id={id}
        sx={{
            width: size,
            height: size,
            margin: '0 5px',
            borderRadius: '50%',
            backgroundColor: color,
            animation: `${bounce} 1.2s infinite ease-in-out`,
            animationDelay: `${delay}s`,
        }}
    />
);

const DotsLoader = ({ count = 3, color = '#FFFFFF', delay = 0.2, size = 12 }: { count?: number, color?: string, delay?: number, size?: number }) => (
    <Box display="flex" justifyContent="center" alignItems="center">
        {Array.from(new Array(count)).map((_e, i) => (
            <Dot
                key={`${color}_${delay}_${i}`}
                id={`${color}_${delay}_${i}`}
                color={color}
                delay={delay * i}
                size={size}
            />
        ))}
    </Box>
);

export default DotsLoader;
