import { FC } from 'react';
import { useTheme, Slider, Box, Typography, useMediaQuery } from '@mui/material';

type FundingCalculatorSliderPropType = {
    id: string;
    value: number;
    onChange: (e: Event, value: number | number[]) => void;
    defaultOptions: { value: number, label: string }[];
    formatSelectedValue?: (val: string | number) => string;
    step?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    error?: boolean;
};

const FundingCalculatorSlider: FC<FundingCalculatorSliderPropType> = (props) => {
    const {
        id,
        value,
        onChange,
        defaultOptions,
        formatSelectedValue,
        step,
        min,
        max,
        error = false,
        disabled = false
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{
                width: isMobile ? "100%" : "23rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                px: "0.4rem"
            }}
        >
            <Typography
                sx={{
                    width: '1rem',
                    textAlign: "center",
                    color: "#989CA2"
                }}
                fontFamily="Manrope, sans-serif"
                fontSize="0.7rem"
                fontWeight={500}
            >
                {min || defaultOptions[0].value}
            </Typography>
            <Slider
                value={value}
                onChange={onChange}
                aria-label="Always visible"
                aria-labelledby={id}
                valueLabelFormat={formatSelectedValue}
                step={step || null}
                marks={defaultOptions}
                min={min || defaultOptions[0].value}
                max={max || defaultOptions[defaultOptions.length - 1].value}
                valueLabelDisplay="on"
                sx={{
                    width: '100%',
                    my: '1rem',
                    mx: '0.8rem',
                    '& .MuiSlider-valueLabel': {
                        backgroundColor: "transparent",
                        color: "#FFFFFF",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: '0.7rem',
                        fontWeight: 700,
                        top: 48,
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            top: -8,
                            left: '49%',
                            transform: 'translateX(-50%)',
                            width: 10,
                            height: 10,
                            borderLeft: '6px solid transparent',
                            borderRight: '6px solid transparent',
                            borderBottom: '6px solid #898C8D',
                            borderRadius: '4px'
                        },
                    },
                    '& .MuiSlider-markLabel': {
                        display: 'none',
                    },
                    '& .MuiSlider-mark': {
                        width: 0,
                        height: 0,
                    },
                    '& .MuiSlider-track': {
                        backgroundImage: "transparent",
                        color: "transparent"
                    },
                    '& .MuiSlider-rail': {
                        backgroundImage: "linear-gradient(to right, #162936, #2F736E, #F2F6F7)",
                        opacity: 1,
                        height: "0.4rem",
                    },
                    '& .MuiSlider-thumb': {
                        height: '1rem',
                        width: '1rem',
                        border: '2px solid',
                        borderColor: "#ACBAA8",
                        backgroundImage: "radial-gradient(circle at center, #F7F7F7, #C9C7C7)",
                    }
                }}
                disabled={disabled}
            />
            <Typography
                sx={{
                    width: '1rem',
                    textAlign: "center",
                    color: "#989CA2"
                }}
                fontFamily="Manrope, sans-serif"
                fontSize="0.7rem"
                fontWeight={500}
            >
                {max || defaultOptions[defaultOptions.length - 1].value}
            </Typography>
        </Box >
    );
}

export default FundingCalculatorSlider;
