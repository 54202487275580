import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as BackArrow } from "../../assets/icons/back_arrow.svg";
import UserDetailsSection from "./components/UserDetailsSection";
import ModuleDetailsSection from "./components/ModuleDetailsSection";
import { NAV_ROUTES } from "../../constants/navRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import useCompany from "../../hooks/api/useCompany";
import useUserModules from "../../hooks/api/useUserModules";
import useInviteUser from "../../hooks/api/useInviteUser";
import { UserPayload } from "../../api/company";
import { useDispatch } from "react-redux";
import { resetAuthorisedModule } from "../../stores/Modules/slice";
import { setToastMessage } from "../../stores/App/slice";
import { getSelectedFilterIds } from "../../utils/common";
import useDebounce from "../../hooks/useDebounce";
import { genericTexts, titles } from "@utils/translations/en";

const UserInvite: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modules = useSelector((state: RootState) => state?.ModulesData);
  const companies = useSelector((state: RootState) => state?.CompanyListData);
  const { getCompanyList } = useCompany();
  const { getUserModules } = useUserModules();
  const { createUser } = useInviteUser();
  const [isShowModuleEditor, setShowModuleEditor] = useState<Boolean>(true);

  const [createuserPayload, setCreateUserPayload] = useState<UserPayload>(
    {
      email: '',
      username: '',
      userRole: 'VIEWER',
      companyId: '',
      submoduleList: []
    }
  );

  // FIXME: temp solun for page title
  document.title = 'PvX | Users';

  useEffect(() => {
    if (!companies?.companyList?.length) {
      getCompanyList();
    }
  }, [companies]);

  useEffect(() => {
    if (!modules?.modules?.length || modules?.adminFetchCount === 0) {
      getUserModules(true);
    }
  }, [modules]);

  // Hook to reset filter selection on unmount
  useEffect(() => () => {
    dispatch(resetAuthorisedModule());
  }, []);

  const handleCreateNewUser = async () => {
    if (
      createuserPayload.companyId
      && createuserPayload.email
    ) {
      createuserPayload.submoduleList.forEach((submodule) => {
        submodule.filterIds = getSelectedFilterIds(modules.modules, submodule.submoduleId);
      });

      const res = await createUser(createuserPayload);
      dispatch(setToastMessage(res?.code ? `${createuserPayload.email} added !` : `Failed to add ${createuserPayload.email}, please try again !`));
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    }
  };

  const debouncedHandleCreateNewUser = useDebounce(handleCreateNewUser, 500);

  const updateNewUserData = (field: string, value: any) => {
    if (field === 'userRole') {
      setShowModuleEditor(value === 'VIEWER')
    }
    setCreateUserPayload({
      ...createuserPayload,
      [field]: field === 'submoduleList' ? [...value] : value,
    });
  };

  return (
    <Container
      id='user_invite_container'
      sx={{
        height: '100%',
        width: '100%',
        p: 0,
        m: 0,
      }}
    >
      <Box
        sx={{
          height: `calc(100vh - ${isMobile ? '220' : '170'}px)`,
          overflow: 'auto',
          pb: 3
        }}
      >
        <UserDetailsSection
          user={createuserPayload}
          isUserDetails={false}
          companies={companies.companyList}
          toggleModuleSection={(val: Boolean) => { setShowModuleEditor(val) }}
          onUpdateData={updateNewUserData}
        />
        <Box
          sx={{
            opacity: !isShowModuleEditor ? "0.4" : "1",
            pointerEvents: !isShowModuleEditor ? "none" : "cursor"
          }}
        >
          <ModuleDetailsSection
            moduleList={createuserPayload.submoduleList}
            onUpdateData={updateNewUserData}
            handleUpdatedSubModuleIds={() => { }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          height: 73,
          width: "calc(100% + 24px)",
          left: "-24px",
          border: "2px solid #2F736E1F",
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "row-reverse",
          gap: 2,
          background: '#FFFFFF'
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: '#2F736E',
            borderRadius: "100px",
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            padding: '10px 24px',
            color: '#F6F8F9',
            '&:hover': {
              background: '#2F736E',
            },
            textTransform: 'none',
          }}
          onClick={() => debouncedHandleCreateNewUser()}
        >
          {genericTexts.Save}
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
          sx={{
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            padding: '10px 24px',
            border: '1px solid #2E4A4F',
            borderRadius: "100px",
            color: '#6C6C6C',
            '&:hover': {
              borderColor: '#2E4A4F',
            },
            textTransform: 'none',
          }}
        >
          {genericTexts.Cancel}
        </Button>
      </Box>
    </Container>
  );
};

export default UserInvite;
