import { useLazyGetUserByIdQuery } from '../../api/userList';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../stores/SelectedUser/slice';

const useUserById = () => {
  const [getUserById, { isLoading, isError }] = useLazyGetUserByIdQuery();

  const dispatch = useDispatch();

  const getUserDetailsById = async (id: string) => {
    try {
      const response = await getUserById(id).unwrap();

      if (response.data) {
        const data = response.data;
        const user = {
          id: data.userId,
          email: data.email,
          status: data.status,
          userRole: data.role,
          joiningDate: data.joiningDate,
          companyId: data.companyId,
          username: data.username
        };
        dispatch(setUserData(user));

        return data;
      }
    } catch (err) {
      console.error('Error while fetching user by id :', err);
    }
  };

  return { getUserDetailsById, isLoading, isError };
};

export default useUserById;
